import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Helmet } from "react-helmet";

import { motion } from "framer-motion"
import FadeIn from 'react-fade-in';

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import AboutHeader from "../components/sections/about/aboutheader"
import AboutLander from "../components/sections/about/aboutlander"
import Team from "../components/sections/about/team"
import Footer from "../components/sections/footer"

import { SectionDivider, HalfColumn, TwoColumn } from "../components/global"

const Merchants = () => (
  <Layout>
    <Helmet>
      <title>About Dili – Who We Are, What We Do & How We Got Here</title>
      <meta name="description" content="Dili is a leading Fintech lending company offering high ticket & long tenure loans at zero cost EMI for the personal & professional growth of progressive India." />
    </Helmet>
    <Navigation></Navigation>
    <FadeIn>
    <AboutLander></AboutLander>
    <SectionDivider>Meet our experienced team</SectionDivider>
    <Team></Team>
    </FadeIn>
    <Footer></Footer>
  </Layout>
)

export default Merchants